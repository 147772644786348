import React from 'react';
import '../../css/themes.css'
import { THEME, THEMELIST } from '../App';

export default function Themes() {
    function handleThemeChange(newTheme) {
        localStorage.setItem(THEME, newTheme)
        window.location.reload()
    }

    return (
        <div id="themes">
            <h1>Themes</h1>
            <div>Customize your RIT Pep Band experience</div>
            <br/>
            <div class="theme-list" id="solid-themes">
                <button value={THEMELIST.default} onClick={() => handleThemeChange(THEMELIST.default)}>Default</button>
                <button value={THEMELIST.dark} onClick={() => handleThemeChange(THEMELIST.dark)} className='dark'>Dark</button>
                <button value={THEMELIST.red} onClick={() => handleThemeChange(THEMELIST.red)} className='red'>Red</button>
                <button value={THEMELIST.blue} onClick={() => handleThemeChange(THEMELIST.blue)} className='blue'>Blue</button>
                <button value={THEMELIST.green} onClick={() => handleThemeChange(THEMELIST.green)} className='green'>Green</button>
                <button value={THEMELIST.purple} onClick={() => handleThemeChange(THEMELIST.purple)} className='purple'>Purple</button>
            </div>
            <br/>
            <div class="theme-list" id="pride-themes">
                
            </div>
            <div class="theme-list" id="seasonal-themes">

            </div>
            <div class="theme-list" id="dev-themes">
              <button value={THEMELIST.patrick} onClick={() => handleThemeChange(THEMELIST.patrick)} className='patrick'>Patrick</button>
              <button value={THEMELIST.jensen} onClick={() => handleThemeChange(THEMELIST.jensen)} className='jensen'>Jensen</button>
            </div>
        </div>
    )
}
