import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { USERID } from '../../App';

const SECTIONS = [
    {name: 'Flutes', checked: false, disabled: false}, 
    {name: 'Clarinets', checked: false, disabled: false},
    {name: 'Saxophones', checked: false, disabled: false},
    {name: 'Trumpets', checked: false, disabled: false},
    {name: 'Mellophones', checked: false, disabled: false},
    {name: 'TenorSaxophones', checked: false, disabled: false},
    {name: 'Trombones', checked: false, disabled: false},
    {name: 'Lows', checked: false, disabled: false},
    {name: 'Percussion', checked: false, disabled: false}
  ];

const STATUSES = [
    {name: 'F', title: 'First Year', checked: true, disabled: false},
    {name: 'M', title: 'Returning Member', checked: true, disabled: false},
    {name: 'P', title: 'Active Alumni', checked: true, disabled: false},
    {name: 'A', title: 'Alumni', checked: false, disabled: false}
]

export default function EmailLists(props) {
    const [users, setUsers] = useState([])
    const [extraSections, setExtraSections] = useState([])
    const [userPage, setUserPage] = useState('')
    const [sections, setSections] = useState(JSON.parse(JSON.stringify(SECTIONS)))
    const [statuses, setStatuses] = useState(JSON.parse(JSON.stringify(STATUSES)))
    const [emailList, setEmailList] = useState([])
    const navigate = useNavigate()

    if(userPage) {
        navigate(`/secretary/user/${userPage}`)
      }

    useEffect(() => {
        let url = `${props.constants.hostname}/api/secretary/users`;
        fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
            if (!res.ok) {
                return false;
            }
            return res.json();
        })
        .then((data) => {
            if (!data) { return; }
            setUsers(data)
        })
        .catch((error) => { console.error(error); });

        url = `${props.constants.hostname}/api/secretary/extraSections`;
        fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
        })
        .then((res) => {
            if (!res.ok) {
                return false;
            }
            return res.json();
        })
        .then((data) => {
            if (!data) { return; }
            setExtraSections(data)
        })
        .catch((error) => { console.error(error); });
    }, [])

    function createEmailLists() {
        const newEmailList = []
        for(const user of users) {
            const sectionChecked = sections.find((s) => s.name === user.SECTION).checked
            const statusChecked = statuses.find((s) => s.name === user.Status).checked
            const userExtraSections = extraSections.filter((es) => es.MemberDCE === user.DCE)
            for(const section of sections) {
                const userSection = userExtraSections.find((us) => us.SECTION === section)
                if(userSection && statusChecked) {
                    newEmailList.push(user)
                }
            }
            if(sectionChecked && statusChecked) {
                newEmailList.push(user)
            }
        }
        newEmailList.sort((a, b) => { return a.DCE.localeCompare(b.DCE) })
        setEmailList(newEmailList)
    }

    function selectAllSections() {
        const tempSections = [...sections]
        for(const section of tempSections) {
            section.checked = true
        }
        setSections(tempSections)        
        createEmailLists()
    }

    function handleSectionChange(section) {
        const tempSections = sections;
        const sect = tempSections.find((s) => s.name === section.name);
        sect.checked = !sect.checked;
        setSections(tempSections)
        createEmailLists()
    }

    function sectionRender() {
        return (
            <div>
                {sections.map((s) => {
                    return (
                        <div>
                        <label 
                            className='secretaryLabel sectionCheck align-center'
                            style={{color: s.disabled ? 'grey' : 'black'}} 
                            key={s.name}
                            disabled={s.disabled}
                        >
                        <input 
                            type='checkbox'
                            className='sectionCheck'
                            onChange={() => handleSectionChange(s)}
                            checked={s.checked}
                            disabled={s.disabled}
                        />{s.name}
                        </label>
                        <br/>
                        </div>
                    )
                })}
            </div>
        )
    }

    function handleStatusChange(status) {
        const tempStatuses = statuses;
        const stat = tempStatuses.find((s) => s.name === status.name);
        stat.checked = !stat.checked;
        setStatuses(tempStatuses)
        createEmailLists()
    }

    function statusRender() {
        return (
            <div>
                {statuses.map((s) => {
                    return (
                        <label 
                            className='secretaryLabel sectionCheck align-center'
                            style={{color: s.disabled ? 'grey' : 'black'}} 
                            key={s.name}
                            disabled={s.disabled}
                        >
                        <input 
                            type='checkbox'
                            className='sectionCheck'
                            onChange={() => handleStatusChange(s)}
                            checked={s.checked}
                            disabled={s.disabled}
                        /> {s.title}
                        </label>
                    )
                })}
            </div>
        )
    }

    function generateRow(user) {
        return (
            <tr key={user.DCE} className='userRow' onClick={() => setUserPage(user.DCE)}>
            <td className='userEntry'>{user.LastName}</td>
            <td className='userEntry'>{user.FirstName}</td>
            <td className='userEntry'>{user.Email}</td>
            </tr>
        )
    }

    function copyEmailList() {
        let emailString = ''
        for(const user of emailList) {
            emailString = `${emailString}${user.Email},`
        }
        navigator.clipboard.writeText(emailString)
        alert('Copied Email List to Clipboard')
    }

    return (
        <div>
            <p>Sections to Include</p>
            <button onClick={selectAllSections}>Select All Sections</button>
            {sectionRender()}
            <br/>
            <p>Statuses to Include</p>
            {statusRender()}
            <br/>
            <br/>
            <button onClick={copyEmailList}>Copy Email List to Clipboard</button>
            <br/>
            <p>Total Members: {emailList.length}</p>
            <div className='tableDiv'>
            <table className='userTable'>
            <thead>
                <tr className='userRow userHeaderRow'>
                <th className='userHeader'>Last Name</th>
                <th className='userHeader'>First Name</th>
                <th className='userHeader'>Email</th>
                </tr>
            </thead>
            <tbody>
                {emailList.map((user) =>
                    generateRow(user)
                )}
            </tbody>
            </table>
        </div>
        </div>
    );
}
