import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <div className='secNav'>
      <Link
        to='/secretary/users'
        className='secNavTile'
      >
        <FontAwesomeIcon icon="users" className='faSecHome'/>
        <p className='secText'>Users</p>
      </Link>
      <Link
        to='/secretary/userEdit'
        className='secNavTile'
      >
        <FontAwesomeIcon icon="user-plus" className='faSecHome'/>
        <p className='secText'>Add User</p>
      </Link>
      <Link
        to='/secretary/fileUpload'
        className='secNavTile'
      >
        <FontAwesomeIcon icon="folder-plus" className='faSecHome'/>
        <p className='secText'>Add Users Via CSV</p>
      </Link>
      <Link
        to='/secretary/emailLists'
        className='secNavTile'
      >
        <FontAwesomeIcon icon='envelope' className='faSecHome marFix'/>
        <p className='secText center'>Email Lists</p>
      </Link>
      <Link
        to='/secretary/events'
        className='secNavTile'
      >
        <FontAwesomeIcon icon="calendar-alt" className='faSecHome'/>
        <p className='secText'>Events</p>
      </Link>
      <Link
        to='/secretary/eventEdit'
        className='secNavTile'
      >
        <FontAwesomeIcon icon="calendar-plus" className='faSecHome'/>
        <p className='secText'>Add Event</p>
      </Link>
      <Link
        to='/secretary/userToEvent'
        className='secNavTile'
      >
        <FontAwesomeIcon icon="calendar-check" className='faSecHome'/>
        <p className='secText'>Add User to Event</p>
      </Link>
      <Link
        to='/secretary/eventFileUpload'
        className='secNavTile'
      >
        <FontAwesomeIcon icon="calendar-week" className='faSecHome'/>
        <p className='secText'>Add Users to Event</p>
      </Link>
      <Link
        to='/secretary/statusAudit'
        className='secNavTile'
      >
        <FontAwesomeIcon icon="clipboard" className='faSecHome'/>
        <p className='secText center'>Standings</p>
      </Link>
      <Link
        to='/secretary/statistics'
        className='secNavTile'
      >
        <FontAwesomeIcon icon="chart-bar" className='faSecHome marFix'/>
        <p className='secText center'>Gather Statistics</p>
      </Link>
    </div>
  );
}
