import React, { useEffect } from 'react';
import { USERID } from '../App'
import { useParams } from 'react-router-dom';

export default function Show(props) {
  const params = useParams()
  useEffect(() => {
    document.title = `${params.title} - ${params.file}`;
  }, [])

  const url = `${props.constants.hostname}/api/music/display?title=${params.title}&file=${params.file}&user=${localStorage.getItem(USERID)}`
  if (params.file.split('.')[1] === 'jpg') {
    return (
      <div id='show'>
        <img
          className={`${props.cssOff ? '' : 'jpg'}`}
          src={url}
          alt={`${params.title} - ${params.file}`}
        />
      </div>
    )
  }
  return (
    <iframe
      className={`${props.cssOff ? '' : 'pdf'}`}
      src={`${url}`}
      title={params.title}
      name={params.title}
      style={{filter: 'invert(\'100%\')'}}
    >
    </iframe>
  );
}